import { ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, Params, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IrisQueryParamsOrderBy } from '@iris/api-query';
import { IrisDMSFileQueryParam, IrisDMSFileQueryParamAction } from '../modules/dms/models/IrisDMSFileQueryParam';
import { ColDef } from 'ag-grid-community';

export const PROJECT_PATH_NAME = 'projectId';

export const getActivatedChild = (route: ActivatedRoute): ActivatedRoute => {
  if (!route.firstChild) {
    return route;
  }

  return getActivatedChild(route.firstChild);
};

export const getQueryParam$ = (
  route: ActivatedRoute,
  queryParamKey: string,
  predicateFn?: (any) => boolean,
): Observable<string> => (
  route.queryParamMap.pipe(
    map((queryParams) => queryParams.get(queryParamKey)),
    filter(predicateFn ? predicateFn : () => true),
  )
);

export const removeQueryParams = (router: Router, paramsToRemove: string[], replaceUrl = true): void => {
  const queryParamsReducer = (paramsList, currentParam) => ({ ...paramsList, [currentParam]: null });
  const queryParams = paramsToRemove.reduce(queryParamsReducer, {});
  router.navigate([], {
    queryParams,
    replaceUrl,
    queryParamsHandling: 'merge',
  });
};

export function getAllRouteParams(route: ActivatedRoute): Observable<Params> {
  return route.parent
    ? combineLatest([route.params, getAllRouteParams(route.parent)]).pipe(
      map(([params, parentParams]) =>  Object.assign({}, parentParams, params)),
    )
    : route.params;
}

export function getAllRouteData(route: ActivatedRoute): Observable<Record<string, unknown>> {
  return route.parent
    ? combineLatest([route.data, getAllRouteData(route.parent)]).pipe(
      map(([data, parentData]) => Object.assign({}, parentData, data)),
    )
    : route.data;
}

export function getRouteDataValue<TData, TProp extends keyof TData>(data: unknown, prop: keyof TData): TData[TProp] {
  return data?.[prop as string];
}

export function getAllRouteParamsFromSnapshot(route: ActivatedRouteSnapshot): Params {
  if (!route.parent) {
    return route.params;
  }

  const parentParams = getAllRouteParamsFromSnapshot(route.parent);
  return Object.assign({}, parentParams, route.params);
}

export const hasQueryParam = (route: ActivatedRoute, param: string): boolean => {
  return route.snapshot.queryParams?.action === param;
};

export const goToPage = (router: Router, commands: string[], options?: NavigationExtras): void => {
  if (!!options) {
    router.navigate(commands, options);
  } else {
    router.navigate(commands);
  }
};

export const toggleSelectedFileQueryParam = (fileId: string, router: Router, activatedRoute: ActivatedRoute): void => {
  const queryParams = {};
  queryParams[IrisDMSFileQueryParam.File] = fileId ?? null;
  queryParams[IrisDMSFileQueryParam.Action] = !!fileId ? IrisDMSFileQueryParamAction.Select : null;
  goToPage(router, [], {
    relativeTo: activatedRoute,
    queryParams,
    queryParamsHandling: 'merge',
  });
};

export const getOrderByQueryParam = (columnDefs: ColDef[]): IrisQueryParamsOrderBy[] => {
  return columnDefs.filter(({ sortable }) => sortable).map(({ field, sort }) =>
    new IrisQueryParamsOrderBy({ name: field, value: sort }),
  );
};
