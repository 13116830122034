import { Injectable } from '@angular/core';
import { ApmService } from '@elastic/apm-rum-angular';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(
    private readonly apmService: ApmService,
  ) { }

  logMessageToKibana(message: string, transactionName = 'Custom message', context = null): void {
    const transaction = this.apmService.apm.startTransaction(transactionName);
    transaction.addLabels({ message });

    if (context) {
      transaction.addContext(context);
    }

    transaction.end();
  }
}
