import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IrisGlobalSettingsI } from '@iris/common/models/IrisGlobalSettings';
import { Observable, of } from 'rxjs';

export enum IrisModuleAliasType {
  System = 'system',
  General = 'general',
  Security = 'security',
  Projects = 'projects',
  Devices = 'devices',
  SensorDataImport = 'sensor-data-import',
  Agents = 'agents',
  SensorManagement = 'sensor-management',
  GeoMonitoring = 'geomonitoring',
  PersonnelManagement = 'personnel-mgmt',
  NotificationManagement = 'notification-mgmt',
  WorkShiftManagement = 'workshift-management',
  Geology = 'geology',
  Maps = 'maps',
  ReportingManagement = 'reporting-mgmt',
  NavigationView = 'navi-view',
  SpoilManagement = 'spoil-mgt',
  IntegratedManagement = 'ims',
  ExternalFilesViewer = 'efv',
  DynamicForms = 'documents',
  DigitalProtocolManagement = 'dpm',
  DamagesManagement = 'dsm',
  CutterToolManagement = 'cuttertool-management',
  BIM = 'bim',
  SegmentManagement = 'segment-management',
  Buildings = 'buildings',
  Workflows = 'workflows',
  Alarming = 'alarming',
  NavigationConfiguration = 'nav-configuration',
  Integrations = 'integrations',
  ExportCsv = 'export-csv',
  Discussions = 'discussions',
  Strathek = 'strathek',
  Translations = 'translations',
  EmailsReader = 'emails-reader',
  SharedMailbox = 'shared-mailbox',
  ResourcePlanning = 'resource-planning',
  EmailClient = 'email-client',
  NotifyRoles = 'notify-roles',
  Mobile = 'mobile',
  Invoice4u = 'invoice4u',
}

@Injectable({ providedIn: 'root' })
export class IrisGlobalSettingsService {

  constructor(private readonly _httpClient: HttpClient) {}

  getGlobalSettingsById$<T extends Partial<Record<keyof T, unknown>>>(alias: IrisModuleAliasType): Observable<IrisGlobalSettingsI<T>> {
    if (!alias) { return of(null); }
    return this._httpClient.get<IrisGlobalSettingsI<T>>(`/global-settings/${alias}`);
  }

  updateGlobalSettings<T extends Partial<Record<keyof T, unknown>>>(alias: IrisModuleAliasType, settings: IrisGlobalSettingsI<T>): Observable<IrisGlobalSettingsI<T>> {
    return this._httpClient.post<IrisGlobalSettingsI<T>>(`/global-settings/${alias}`, settings);
  }
}
