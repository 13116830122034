import { copyValues } from '../../../utils/object.utils';

export interface IrisUserInfoI {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  username: string;
  departmentId: number;
  companyId: number;
  fullName: string;
  avatarFileId: string;
  enabled: boolean;
  mobileNumber?: string;
  phone?: string;
}

export class IrisUserInfo implements IrisUserInfoI {
  id: number = undefined;
  email: string = undefined;
  firstname: string = undefined;
  lastname: string = undefined;
  username: string = undefined;
  departmentId: number = undefined;
  companyId: number = undefined;
  fullName: string = undefined;
  avatarFileId: string = undefined;
  enabled: boolean = undefined;
  mobileNumber: string = undefined;
  phone: string = undefined;

  constructor(params?: Partial<IrisUserInfoI>) {
    if (params) {
      copyValues(this, params);
    }
  }
}

export const IRIS_CURRENT_USER_ID = -2;

export type IrisUserInfoField = 'id'
  | 'email'
  | 'firstname'
  | 'lastname'
  | 'username'
  | 'departmentId'
  | 'companyId'
  | 'fullName'
  | 'avatarFileId'
  | 'enabled'
  ;
