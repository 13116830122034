import { select, Store } from '@ngrx/store';
import * as fromGlobal from './reducers/index';
import { LoadUserByEmail, LoadUserInfo, LoadUserInfoComplete } from './actions/users.actions';
import { Injectable } from '@angular/core';
import { InitCompaniesStart, InitCountriesStart, LoadInitLanguages, LoadUserGroupsStart } from '../modules/init-store/actions/init.actions';
import * as globalReducer from '@iris/common/redux/reducers';
import { GetGlobalSettingsStart, UpdateGlobalSettingsStart } from '@iris/common/redux/actions/settings-actions';
import { IrisModuleAliasType } from '@iris/common/services/settings/global-settings.service';
import { IrisUserGroupI } from '../modules/user-common/models/IrisUserGroup';
import { Observable, of, Subject } from 'rxjs';
import { IrisCountryI } from '../models/IrisCountry';
import { IrisProjectI, IrisProjectPage } from '@iris/common/models';
import { catchError, concatMap, filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { UpdateUserGroup } from '@iris/common/redux/actions/user-groups.action';
import { changeCurrentProjectId, loadProjectTypes, setRecentProjectsIds } from '@iris/common/redux/actions/projects.actions';
import { IrisCompanyI } from '../models/IrisCompany';
import { LoadUnits } from './actions/units-actions';
import { IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisProjectsService } from '../services/projects.service';
import { ColumnsIds } from '@iris/modules/projects/projects-list/models/projects-list-columns';
import { IrisGlobalSettingsI } from '@iris/common/models/IrisGlobalSettings';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';
import { TranslateService } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class IrisGlobalSandbox {
  private readonly projectChangedManuallySubject = new Subject<number>();
  readonly projectChangedManually$ = this.projectChangedManuallySubject.asObservable().pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  projects$: Observable<IrisProjectI[]> = this.store.pipe(select(globalReducer.getGlobalProjects));
  currentProject$: Observable<IrisProjectI> = this.store.pipe(select(fromGlobal.getCurrentProject));
  currentProjectId$: Observable<number> = this.store.pipe(select(fromGlobal.getCurrentProjectId));
  readonly currentProjectId = toSignal(this.currentProjectId$);
  lastProjectIdFromRecent$: Observable<number> = this.store.pipe(select(fromGlobal.getLastProjectIdFormRecent));
  recentProjectsIds$: Observable<number[]> = this.store.pipe(select(fromGlobal.getRecentProjectsIds));
  recentProjects$: Observable<IrisProjectI[]> = this.store.pipe(select(fromGlobal.getRecentProjects));
  countries$ = this.store.select(fromGlobal.getGlobalCountries).pipe(
    tap(countries => {
      if (!countries) {
        this.store.dispatch(new InitCountriesStart());
      }
    }),
    filter(countries => countries !== null),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  companies$ = this.store.select(fromGlobal.getGlobalCompanies).pipe(
    tap(companies => {
      if (!companies) {
        this.store.dispatch(new InitCompaniesStart());
      }
    }),
    filter(companies => companies !== null),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  projectTypes$ = this.store.select(fromGlobal.getProjectTypes).pipe(
    tap(types => {
      if (!types) {
        this.store.dispatch(loadProjectTypes());
      }
    }),
    filter(types => types !== null),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  units$ = this.store.select(fromGlobal.getGlobalUnits).pipe(
    tap(units => {
      if (!units) {
        this.store.dispatch(new LoadUnits());
      }
    }),
    filter(units => units !== null),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  languages$ = this.store.select(fromGlobal.getGlobalLanguages).pipe(
    tap(languages => {
      if (!languages) {
        this.store.dispatch(new LoadInitLanguages());
      }
    }),
    filter(languages => languages !== null),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  constructor(
    private readonly store: Store<fromGlobal.State>,
    private readonly projectsService: IrisProjectsService,
    private readonly translate: TranslateService,
  ) { }

  userInfo$(userId: number): Observable<IrisUserInfoI> {
    return this.store.pipe(select(fromGlobal.getGlobalUserInfoById(userId)));
  }

  userByEmail$(email: string): Observable<IdentUserI> {
    return this.store.pipe(select(fromGlobal.getGlobalUserByEmail(email)));
  }

  departmentPath$(departmentId: string): Observable<string> {
    return this.store.pipe(select(fromGlobal.getDepartmentPathById(departmentId)));
  }

  loadUserInfo(userId: number): void {
    this.store.dispatch(new LoadUserInfo(userId));
  }

  setLoadedUsers(users: IdentUserI[]): void {
    this.store.dispatch(new LoadUserInfoComplete(users));
  }

  loadUserByEmail(email: string): void {
    this.store.dispatch(new LoadUserByEmail(email));
  }

  getUserGroups(params: IrisQueryParams = new IrisQueryParams()): void {
    this.store.dispatch(new LoadUserGroupsStart(params));
  }

  userGroups$(companyId?: number): Observable<IrisUserGroupI[]> {
    return this.store.pipe(select(fromGlobal.getGlobalUserGroups(companyId)));
  }

  loadGlobalSettings(alias: IrisModuleAliasType): void {
    this.store.dispatch(GetGlobalSettingsStart({ alias }));
  }

  updateGlobalSettings<T extends Partial<Record<keyof T, unknown>>>(alias: IrisModuleAliasType, settings: Partial<IrisGlobalSettingsI<T>>): void {
    this.store.dispatch(UpdateGlobalSettingsStart(alias, { ...settings, alias }));
  }

  globalSettingsByAlias$<T>(alias: IrisModuleAliasType): Observable<IrisGlobalSettingsI<T>> {
    return this.store.pipe(select(fromGlobal.getGlobalSettingsByAlias(alias)), map((x) => x as IrisGlobalSettingsI<T>));
  }

  getCountryById(id: number): Observable<IrisCountryI> {
    return this.countries$.pipe(
      map(countries => countries.find(c => c.id == id)),
    );
  }

  getCompanyById(id: number): Observable<IrisCompanyI> {
    return this.companies$.pipe(
      map(companies => companies.find(c => c.id == id)),
    );
  }

  updateUserGroup(userGroup: IrisUserGroupI): void {
    this.store.dispatch(new UpdateUserGroup(userGroup));
  }

  setRecentProjectsIds(projectsIds: number[]): void {
    this.store.dispatch(setRecentProjectsIds({ projectsIds }));
  }

  changeCurrentProjectId(projectId: number, manually = false): void {
    this.store.dispatch(changeCurrentProjectId({ projectId }));
    if (manually) {
      this.projectChangedManuallySubject.next(projectId);
    }
  }

  getRecentProjects(): Observable<IrisProjectPage> {
    return this.recentProjectsIds$.pipe(
      take(1),
      concatMap(recentProjectsIds => {
        if (!recentProjectsIds.length) {
          return of({} as IrisProjectPage);
        } else {
          const params = new IrisQueryParamsBuilder()
            .urlParam('kind', 'ALL')
            .filter('id', recentProjectsIds, t => t.strict(false))
            .onlyFields([
              ColumnsIds.Name, ColumnsIds.From, ColumnsIds.To, ColumnsIds.Progress, ColumnsIds.LD, ColumnsIds.FCO,
              ColumnsIds.KOST, 'id', 'code', 'costCenter', 'count', 'elements', 'isCompleted', 'warrantyEnd',
              'warrantyStart', 'translations', this.translate.currentLang,
            ]);

          return this.projectsService.getProjectsPageInfo(params.toStructure()).pipe(
            tap(recentProjects => recentProjects.elements.sort((a, b) => recentProjectsIds.indexOf(a.id) - recentProjectsIds.indexOf(b.id))),
            catchError(() => of({} as IrisProjectPage)));
        }
      }),
    );
  }

  getProjectInfo(projectId: number): Observable<IrisProjectI> {
    return this.projectsService.getProjectInfo(projectId);
  }
}
